// Config
import { defaultBestPlacesCategories } from 'config/LocationLanding'
import { mostPopularAmenities } from 'config/Search'

// Types
import type { CountObject, TopFacet } from 'types/locationLanding'
type CategoryCountObject = { [key: string]: CountObject }

// TopFacets functions
// Add/Update listing facet (amenities, property types) counts
const updateFacetCounts = (countObj: CountObject, facets: string[]) => {
  facets.forEach((facet) => {
    if (countObj[facet]) {
      countObj[facet] = countObj[facet] + 1
    } else {
      countObj[facet] = 1
    }
  })

  return countObj
}

// Set initial listing facet (amenities, property types) counts
const setFacetCounts = (facets: string[]) => {
  return facets.reduce((countObj: CountObject, facet: string) => {
    if (countObj[facet]) {
      countObj[facet] = countObj[facet] + 1
    } else {
      countObj[facet] = 1
    }
    return countObj
  }, {})
}

// Accumulate, categorize and count relevant listing facets (amenities, property types)
export const getListingsFacetsCounts = (listings: any[]) => {
  return listings.reduce((facetCounts: CategoryCountObject, listing: any) => {
    for (const amenCategory in listing['amenities']) {
      facetCounts[amenCategory] = facetCounts[amenCategory]
        ? updateFacetCounts(
            facetCounts[amenCategory],
            listing['amenities'][amenCategory],
          )
        : setFacetCounts(listing['amenities'][amenCategory])

      if (amenCategory === 'Amenities') {
        const popularAmens = listing['amenities'][amenCategory].filter(
          (amen: string) =>
            amen !== 'Kitchen' && mostPopularAmenities.includes(amen),
        )
        facetCounts['mostPopular'] = facetCounts['mostPopular']
          ? updateFacetCounts(facetCounts['mostPopular'], popularAmens)
          : setFacetCounts(popularAmens)
      }
    }

    facetCounts['propertyType'] = facetCounts['propertyType']
      ? updateFacetCounts(facetCounts['propertyType'], [
          listing['Property Type'],
        ])
      : { [listing['Property Type']]: 1 }

    return facetCounts
  }, {})
}

// Replace default Best Places to Stay amenities with up to 2 fallbacks
const checkMinFacetCount = (
  listingsFacetsCounts: CategoryCountObject,
  topFacets: TopFacet[],
) => {
  const filteredTopFacets = topFacets.filter(
    (facet) => facet.count && facet.count > 0,
  )

  if (filteredTopFacets.length < 6) {
    const difference = 6 - filteredTopFacets.length
    const fallbackFacets = [...Array(difference).fill({})]

    fallbackFacets.map((_, i) => {
      if (i === 0) {
        fallbackFacets[i] = {
          category: 'Accessibility',
          name: 'Suitable for Children',
          count: listingsFacetsCounts['Accessibility']['Suitable for Children'],
        }
      }
      if (i === 1) {
        // Get most frequent 'View'
        fallbackFacets[i] = Object.keys(listingsFacetsCounts['View'])
          .sort(
            (a, b) =>
              listingsFacetsCounts['View'][b] - listingsFacetsCounts['View'][a],
          )
          .slice(0, 1)
          .reduce((topFacet, facet) => {
            return (topFacet = {
              category: 'View',
              name: facet,
              count: listingsFacetsCounts['View'][facet] ?? 0,
            })
          }, {} as TopFacet)
      }
    })

    return [...filteredTopFacets, ...fallbackFacets]
  }

  return topFacets
}

// Get ordered/formatted list of top listing facets
export const getTopFacets = (
  listingsLength: number,
  listingsFacetsCounts: CategoryCountObject,
): TopFacet[] => {
  const allTopFacets = defaultBestPlacesCategories.map((category) => {
    if (category === 'listings') {
      return {
        category: 'listings',
        name: 'All Properties',
        count: listingsLength ?? 0,
      }
    } else if (category === 'pets') {
      return {
        category: 'pets',
        name: 'Pets Allowed',
        count: listingsFacetsCounts['Accessibility']['Pets Allowed'] ?? 0,
      }
    } else if (category === 'parking') {
      return {
        category: 'parking',
        name: 'Parking Available',
        count: listingsFacetsCounts['Amenities']['Parking Available'] ?? 0,
      }
    } else if (listingsFacetsCounts[category]) {
      return Object.keys(listingsFacetsCounts[category])
        .sort(
          (a, b) =>
            listingsFacetsCounts[category][b] -
            listingsFacetsCounts[category][a],
        )
        .slice(0, 1)
        .reduce((topFacet, facet) => {
          return (topFacet = {
            category: category,
            name: facet,
            count: listingsFacetsCounts[category][facet] ?? 0,
          })
        }, {} as TopFacet)
    } else {
      return {
        category: '',
        name: '',
        count: 0,
      }
    }
  })

  return checkMinFacetCount(listingsFacetsCounts, allTopFacets)
}

export const getLocationFacetDisplayText = (facetName: string) => {
  switch (facetName) {
    case 'Riverfront':
    case 'Lakefront':
    case 'Private Beach':
    case 'Beachfront':
    case 'Bayfront':
    case 'Waterfront':
    case 'Oceanfront':
    case 'Ski-In/Ski-Out':
    case 'Downtown':
      return `${facetName} Vacation Rentals`
    case 'Hiking Trails':
    case 'Hiking Trail Access On-Site':
    case 'Fishing':
    case 'Cross-Country Skiing Trails':
    case 'Lake Access':
    case 'Walk to Chair Lift':
    case 'Walk to Beaches':
    case 'Golf Courses':
      return `Vacation Rentals with ${facetName}`
    case 'Near Ocean':
    case 'Near Marina':
      return `Vacation Rentals ${facetName}`
    default:
      return `${facetName} Vacation Rentals`
  }
}
