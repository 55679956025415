import React from 'react'

import DifferenceItem from './DifferenceItem'
import style from './EvolveDifference.module.scss'

type Difference = { icon: any; title: string; description: string }

export type EvolveDifferenceProps = {
  small: boolean
  evolveDifference: any
  boldSubtitle?: boolean
  styleClass?: string
  isLLP?: boolean
  isVWO?: boolean
}

const EvolveDifference: React.FC<EvolveDifferenceProps> = ({
  small,
  evolveDifference,
  boldSubtitle,
  styleClass,
  isLLP = false,
  isVWO = false,
}) => {
  // TODO: This is a temporal component and should be removed when DD-404 is redefined
  if (isVWO) return <EvolveDifferenceVWO />

  const smallStyle = `${small ? style.small : ''}`
  const llpStyle = `${isLLP ? style.sectionLLP : ''}`
  const baseStyle = `${style.section} ${styleClass ? style[styleClass] : ''}`

  return (
    <section
      className={`${baseStyle} ${smallStyle} ${llpStyle}`}
      id="evolve-difference"
    >
      <div className={style.content}>
        {evolveDifference && (
          <div className={style.benefits}>
            <span className="section-label-sm blue">
              {evolveDifference.label}
            </span>
            <h2 className="section-title-sm">{evolveDifference.title}</h2>
            <span
              className={`section-subtitle-sm ${
                boldSubtitle ? 'lessBold' : ''
              }`}
            >
              {evolveDifference.main_text}
            </span>
          </div>
        )}
        <div className={style.differenceGrid}>
          {evolveDifference['differences'] &&
          evolveDifference['differences'].length
            ? evolveDifference['differences'].map(
                ({ icon, title, description }: Difference, i: number) => (
                  <DifferenceItem
                    description={description}
                    icon={icon}
                    key={i}
                    styleClass={styleClass}
                    title={title}
                  />
                ),
              )
            : null}
        </div>
      </div>
    </section>
  )
}

// TODO: Delete me when DD-404 is redefined
const EvolveDifferenceVWO: React.FC<{}> = () => {
  return (
    <div className={style.evolveDifference}>
      <div className={style.evolveDifferenceBenefits}>
        <span className={style.evolveDifferenceTitle}>
          THE EVOLVE DIFFERENCE
        </span>
        <h2 className={style.evolveDifferenceSubtitle}>
          Private Homes with Hospitality
        </h2>
        <span className={style.evolveDifferenceDescription}>
          Enjoy a unique, professionally-cleaned vacation home backed by 24/7
          guest support so you can travel without worry.
        </span>
      </div>
      <div className={style.evolveDifferenceGrid}>
        <div className={style.evolveDifferenceItem}>
          <div className={`${style.evolveDifferenceIcon} ${style.rate}`}>
            <img
              alt="Best Rate"
              src="https://wp.evolvevacationrental.com/wp-content/uploads/2021/06/BestRates_100x100.svg"
            />
          </div>
          <div className={style.evolveDifferenceContent}>
            <p className={style.evolveDifferenceItemLabel}>Best Rate</p>
            <span className={style.evolveDifferenceItemDescription}>
              Get the guaranteed best rates on our vacation homes exclusively at
              evolve.com.
            </span>
          </div>
        </div>
        <div className={style.evolveDifferenceItem}>
          <div className={`${style.evolveDifferenceIcon} ${style.rest}`}>
            <img
              alt="Rest Easy"
              src="https://wp.evolvevacationrental.com/wp-content/uploads/2019/12/RestEasyPromise-1.svg"
            />
          </div>
          <div className={style.evolveDifferenceContent}>
            <p className={style.evolveDifferenceItemLabel}>Rest Easy</p>
            <span className={style.evolveDifferenceItemDescription}>
              If your plans change or the home isn’t what we promised, we’ll be
              there for you.
            </span>
          </div>
        </div>
        <div className={style.evolveDifferenceItem}>
          <div className={`${style.evolveDifferenceIcon} ${style.back}`}>
            <img
              alt="Icon showing an outstretched hand holding a heart icon"
              src="https://wp.evolvevacationrental.com/wp-content/uploads/2021/06/GivingBack_100x100.svg"
            />
          </div>
          <div className={style.evolveDifferenceContent}>
            <p className={style.evolveDifferenceItemLabel}>Give Back</p>
            <span className={style.evolveDifferenceItemDescription}>
              We donate for every stay booked here, so staying in our homes
              helps others stay in theirs.
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EvolveDifference
