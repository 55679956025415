import React, { useState, useEffect } from 'react'
import Script from 'next/script'

import { useDispatch } from 'store/index'
import { setGtmLoaded as setGtmLoadedInSearch } from 'store/search'

import { pushToDataLayer } from 'utils/Gtm'

import { googleTagManagerParameters } from 'constants/globalEnv'

type GtmProps = {
  pageType: string
  callback?: (...args: any[]) => void | Promise<void>
  wait?: boolean
}

const Gtm: React.FC<GtmProps> = ({ pageType, callback, wait }) => {
  const [shouldLoadGtm, setShouldLoadGtm] = useState(false)
  const [gtmLoaded, setGtmLoaded] = useState(false)
  const [pageTypePushed, setPageTypePushed] = useState(false)

  const appDispatch = useDispatch()

  useEffect(() => {
    if (gtmLoaded) {
      // push page view event to GTM data layer
      if (!pageTypePushed) {
        pushToDataLayer('pageType', { pageType: pageType })
        setPageTypePushed(true)
      }
      callback?.()
    } else {
      ;(window as any).handleGtmLoad = () => {
        setGtmLoaded(true)
        appDispatch(setGtmLoadedInSearch())
      }
      !wait && setShouldLoadGtm(true)
    }
  }, [pageType, gtmLoaded, callback, wait, appDispatch, pageTypePushed])

  return shouldLoadGtm ? (
    <Script
      onLoad={() => (window as any).handleGtmLoad()}
      src={`https://www.googletagmanager.com/gtm.js?id=GTM-5XLC${googleTagManagerParameters}`}
      strategy="afterInteractive"
    />
  ) : null
}

export default React.memo(Gtm)
