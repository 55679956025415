// Amenities checked for FAQ generation
export const faqAmenities = {
  parking: { amenities: 'Parking Available', checkType: 'includes' },
  ac: { amenities: 'Air-Conditioning', checkType: 'includes' },
  wifi: { amenities: 'Wifi/Internet', checkType: 'includes' },
  kitchen: { amenities: 'Kitchen', checkType: 'includes' },
  pets: { amenities: 'Pets Allowed', checkType: 'includes' },
  children: { amenities: 'Suitable for Children', checkType: 'includes' },
  families: {
    amenities: ['Suitable for Children', 'Suitable for Elderly'],
    checkType: 'includesTwo',
  },
  views: {
    amenities: [
      'Beach View',
      'Golf Course View',
      'Lake View',
      'Mountain View',
      'Ocean View',
      'City / Skyline View',
      'Water View',
    ],
    checkType: 'includesAny',
  },
  pool: {
    amenities: ['Pool (community)', 'Pool'],
    checkType: 'includesAny',
  },
  hotTub: {
    amenities: 'Private Hot Tub',
    checkType: 'includes',
  },
}

// Default amenity categories for Best Places to Stay list
export const defaultBestPlacesCategories = [
  'listings',
  'propertyType',
  'pets',
  'mostPopular',
  'Location',
  'parking',
]

// Define regions to categorize regional links
export const regionMap = {
  us: {
    ak: ['The West'],
    al: ['The South', 'Gulf Coast'],
    ar: ['The South'],
    az: ['The West', 'Southwest'],
    ca: ['The West'],
    co: ['The West'],
    ct: ['Northeast', 'New England'],
    dc: ['Northeast'],
    de: ['Northeast'],
    fl: ['The South', 'Gulf Coast'],
    ga: ['The South'],
    hi: ['The West'],
    ia: ['Midwest'],
    id: ['The West', 'Pacific Northwest'],
    il: ['Midwest', 'Great Lakes'],
    in: ['Midwest', 'Great Lakes'],
    ks: ['Midwest'],
    ky: ['The South'],
    la: ['The South', 'Gulf Coast'],
    ma: ['Northeast', 'New England'],
    md: ['Northeast'],
    me: ['Northeast', 'New England'],
    mi: ['Midwest', 'Great Lakes'],
    mn: ['Midwest', 'Great Lakes'],
    mo: ['Midwest'],
    ms: ['The South', 'Gulf Coast'],
    mt: ['The West'],
    nc: ['The South'],
    nd: ['Midwest'],
    ne: ['Midwest'],
    nh: ['Northeast', 'New England'],
    nj: ['Northeast'],
    nm: ['The West', 'Southwest'],
    nv: ['The West', 'Southwest'],
    ny: ['Northeast'],
    oh: ['Midwest', 'Great Lakes'],
    ok: ['The South'],
    or: ['The West', 'Pacific Northwest'],
    pa: ['Northeast'],
    pr: ['Caribbean'],
    ri: ['Northeast', 'New England'],
    sc: ['The South'],
    sd: ['Midwest'],
    tn: ['The South'],
    tx: ['The South', 'Gulf Coast'],
    ut: ['The West', 'Southwest'],
    va: ['The South'],
    vi: ['Caribbean'],
    vt: ['Northeast', 'New England'],
    wa: ['The West', 'Pacific Northwest'],
    wi: ['Midwest', 'Great Lakes'],
    wv: ['The South'],
    wy: ['The West'],
  },
  mx: ['Mexico'],
}

// Order for regional links
export const regionOrder = [
  'Northeast',
  'New England',
  'Midwest',
  'Great Lakes',
  'The South',
  'Gulf Coast',
  'The West',
  'Pacific Northwest',
  'Southwest',
  'Caribbean',
  'Mexico',
]

export const listingThreshold = 10
