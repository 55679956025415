export const BASE_SEARCH_API_URL = process.env.NEXT_PUBLIC_BASE_SEARCH_API_URL

export const mostPopularAmenities = [
  'Pool',
  'Pool (community)',
  'Private Hot Tub',
  'Wifi/Internet',
  'Air-Conditioning',
  'Kitchen',
]

export const avgNightCopy = ' Avg. / night'

export const totalPriceCopy = ' Total'

export const taxesFeesCopy = ' (before taxes & fees)'

export const taxesCopy = ' before taxes'
