import React, { useState, useEffect, useCallback, useMemo } from 'react'
import type { Dexie as DexieType } from 'dexie'

import { useDispatch, useSelect } from 'store/index'
import {
  resetSearchState,
  setQuery,
  setSelectedDates,
  setSelectedGuests,
} from 'store/search'

import { setGuestPickerFocused, closeDatePicker } from 'reducers/uiState'

import useAugmentedRouter from 'hooks/useAugmentedRouter'
import type { IRecentSearch } from 'hooks/useRecentSearches'

import CustomLink from 'components/Link/CustomLink'

import SearchBox from './SearchBox'
import Dates from './Filters/Dates/Dates'
import Guests from './Filters/Guests/Guests'
import style from './SearchBar.module.scss'

import { pushToDataLayer } from 'utils/Gtm'
import { urlPrettySpaces, normalizeSearchQuery } from 'utils/Strings'

import type { InitialSearch } from 'types/externalData'

type SearchBarProps = {
  initialSearch?: InitialSearch
  searchBarClass?: string
  isAutoPage?: boolean
}

const SearchBar: React.FC<SearchBarProps> = ({
  initialSearch,
  searchBarClass,
  isAutoPage,
}) => {
  const router = useAugmentedRouter()
  const [searchQuery, setSearchQuery] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [guests, setGuests] = useState(
    initialSearch?.guests
      ? {
          adults: parseInt(initialSearch.guests.adults),
          children: parseInt(initialSearch.guests.children),
          infants: parseInt(initialSearch.guests.infants),
          pets: parseInt(initialSearch.guests.pets),
          total:
            parseInt(initialSearch.guests.adults) +
            parseInt(initialSearch.guests.children),
        }
      : {
          adults: 0,
          children: 0,
          infants: 0,
          pets: 0,
          total: 0,
        },
  )
  const [shouldApplyGuests, setShouldApplyGuests] = useState(true)

  const width = useSelect((state) => state.uiState.width)

  const datePickerFocused = useSelect(
    (state) => state.uiState.datePickerFocused,
  )
  const appDispatch = useDispatch()

  const searchHref = useMemo(() => {
    const query = {
      ...(searchQuery && {
        query: urlPrettySpaces(normalizeSearchQuery(searchQuery)),
      }),
      ...(startDate && { startDate: startDate }),
      ...(endDate && { endDate: endDate }),

      ...(shouldApplyGuests &&
        guests.adults && { adults: guests.adults.toString() }),
      ...(shouldApplyGuests &&
        guests.children && {
          children: guests.children.toString(),
        }),
      ...(shouldApplyGuests &&
        guests.infants && {
          infants: guests.infants.toString(),
        }),
      ...(shouldApplyGuests &&
        guests.pets && {
          'pets': guests.pets.toString(),
          'amenities.Accessibility': 'Pets-Allowed',
        }),
    }

    return { pathname: '/vacation-rentals/search', query: query }
  }, [
    endDate,
    guests.adults,
    guests.children,
    guests.infants,
    guests.pets,
    searchQuery,
    startDate,
    shouldApplyGuests,
  ])

  const handleSubmitClick = useCallback(async () => {
    const hasSearchCriteria =
      searchQuery || startDate || endDate || Object.keys(guests).length > 0

    if (hasSearchCriteria) {
      if (searchQuery) {
        appDispatch(setQuery(searchQuery))
        const { Dexie } = await import('dexie')

        class RecentSearchDatabase extends Dexie {
          searches: DexieType.Table<IRecentSearch, string>

          constructor() {
            super('RecentSearchDatabase')
            this.version(1).stores({
              searches: 'timestamp',
            })
            this.searches = this.table('searches')
          }
        }

        const db = new RecentSearchDatabase()

        db.searches.add({
          query: '?' + new URLSearchParams(searchHref.query).toString(),
          data: searchHref.query,
          timestamp: new Date().valueOf(),
        })
      }

      if (startDate && endDate) {
        appDispatch(
          setSelectedDates({
            start: startDate,
            end: endDate,
          }),
        )
      }

      if (shouldApplyGuests && guests && Object.keys(guests).length) {
        guests.total && appDispatch(setSelectedGuests(guests.total))
      }

      appDispatch(setGuestPickerFocused(false))
      appDispatch(closeDatePicker())
    }
    router.push(searchHref)
  }, [
    appDispatch,
    endDate,
    guests,
    searchQuery,
    startDate,
    searchHref,
    shouldApplyGuests,
    router,
  ])

  useEffect(() => {
    function logKey(e) {
      if (e.key === 'Enter') {
        handleSubmitClick()
      }
    }
    window.addEventListener('keydown', logKey)
    return () => window.removeEventListener('keydown', logKey)
  }, [handleSubmitClick])

  useEffect(() => {
    appDispatch(resetSearchState({}))
  }, [appDispatch])

  useEffect(() => {
    if (startDate && endDate && !datePickerFocused && !guests.adults) {
      appDispatch(setGuestPickerFocused(true))
    } else {
      appDispatch(setGuestPickerFocused(false))
    }
  }, [appDispatch, datePickerFocused, endDate, startDate, guests.adults])

  const handleSearchQueryChange = useCallback((query) => {
    setSearchQuery(query)
    // push area searched to GTM data layer
    pushToDataLayer('areaSearched', { areaSearched: query })
  }, [])

  const handleQuerySelect = (query: string) => {
    setSearchQuery(query)
  }

  const handleDatesChange = useCallback(
    (startDate: string, endDate: string) => {
      setStartDate(startDate ?? '')
      setEndDate(endDate ?? '')

      appDispatch(
        setSelectedDates({
          start: startDate ?? '',
          end: endDate ?? '',
        }),
      )
    },
    [appDispatch],
  )

  const handleApplyGuests = useCallback(
    (adultGuests, childGuests, infantGuests, petGuests, totalGuests) => {
      setShouldApplyGuests(true)
      setGuests({
        adults: adultGuests,
        children: childGuests,
        infants: infantGuests,
        pets: petGuests,
        total: totalGuests,
      })
      appDispatch(setSelectedGuests(parseInt(totalGuests)))

      // push guest count, and pets added events to GTM data layer
      pushToDataLayer('guestCount', { guestCount: totalGuests })
      pushToDataLayer('petsAdded', { petsAdded: petGuests === 1 })
    },
    [appDispatch],
  )

  const handleOnClearGuests = useCallback(() => {
    setShouldApplyGuests(true)
    setGuests({
      adults: 0,
      children: 0,
      infants: 0,
      pets: 0,
      total: 0,
    })
    appDispatch(setSelectedGuests(0))

    // push guest count, and pets added events to GTM data layer
    pushToDataLayer('guestCount', { guestCount: 0 })
    pushToDataLayer('petsAdded', { petsAdded: false })
  }, [appDispatch])

  const handleOnClearDates = () => {
    setStartDate('')
    setEndDate('')
  }

  return (
    <div
      className={`${style.searchBar} ${searchBarClass ? searchBarClass : ''}`}
    >
      <div className={style.searchBox}>
        <SearchBox
          initialAddress={initialSearch?.address?.address}
          onQueryChange={handleSearchQueryChange}
          onQuerySelect={handleQuerySelect}
        />
      </div>
      <div className={style.dates}>
        <Dates
          direction={isAutoPage ? 'none' : width < 1170 ? 'right' : 'left'}
          initialEndDate={initialSearch?.check_out}
          initialStartDate={initialSearch?.check_in}
          onChange={handleDatesChange}
          onClearDates={handleOnClearDates}
        />
      </div>
      <div className={style.guests}>
        <Guests
          data-testid="guestsBtn"
          hasIcon={true}
          onApply={handleApplyGuests}
          onClearGuests={handleOnClearGuests}
          petsAllowed={true}
          value={guests}
        />
      </div>
      <div className={style.submit}>
        <CustomLink
          className="btn-primary btn-cta"
          data-testid="searchHomesBtn"
          href={searchHref}
          id="search_homes_btn"
          onClick={handleSubmitClick}
          prefetch={false}
        >
          Search Homes
        </CustomLink>
      </div>
    </div>
  )
}

export default SearchBar
