import React from 'react'

// Style
import style from './DifferenceItem.module.scss'

export type DifferenceItemProps = {
  icon: any
  title: string
  description: string
  large?: boolean
  white?: boolean
  styleClass?: string
}

const DifferenceItem: React.FC<DifferenceItemProps> = ({
  icon,
  title,
  description,
  large,
  white,
  styleClass,
}) => {
  return (
    <div
      className={`${large ? style.differenceLg : style.difference}
      ${styleClass ? style[styleClass] : ''}
      `}
    >
      <div
        className={`${large ? style.differenceIconLg : style.differenceIcon} ${
          white && style.differenceIconWhite
        }`}
      >
        <img alt={icon.alt ? icon.alt : title} src={icon.url} />
      </div>
      <div className={style.differenceContent}>
        {title && (
          <p
            className={`${style.differenceLabel} ${
              white && style.differenceLabelWhite
            }`}
          >
            {title}
          </p>
        )}
        <span
          className={`${style.differenceDescription} ${
            white && style.differenceDescriptionWhite
          }`}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  )
}

export default DifferenceItem
